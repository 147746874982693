'use client';

import { FC } from 'react';
import LinkPreviewSkeleton from 'components/LinkDetail/components/Skeleton/LinkPreviewSkeleton';
import Container from 'components/Layouts/Container/Container';

const Loading: FC = () => {
  return (
    <Container>
      <LinkPreviewSkeleton />
    </Container>
  );
};

export default Loading;
